import { Button, lighten, styled } from '@mui/material';

export const ProjectFormElement = styled('form')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  rowGap: theme.spacing(3),
  padding: theme.spacing(1),
}));

export const AddExperimentButton = styled(Button)(({ theme }) => ({
  backgroundColor: lighten(theme.palette.error.light, 0.1),
  '&:hover': {
    backgroundColor: lighten(theme.palette.error.light, 0.2),
  },
}));
