import { useState } from 'react';
import {
  Button,
  FormControl,
  Input,
  InputAdornment,
  InputLabel,
} from '@mui/material';
import { useFormContext } from 'react-hook-form';

import { type FormField } from '@pages/common/Login/types';
import HelperTextContainer from '@components/HelperTextContainer';
import { type LoginSchema } from '@/zod_schemas';
import ResetPassword from './ResetPassword';

const loginFormFields: FormField[] = [
  {
    id: 'email',
    label: 'E-Mail',
    type: 'email',
    required: true,
    describedby: 'email field',
  },
  {
    id: 'password',
    label: 'Password',
    type: 'password',
    required: true,
    describedby: 'password field',
  },
];

const LoginOtherFields = () => {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const {
    register,
    formState: { errors },
  } = useFormContext<LoginSchema>();

  return (
    <>
      {loginFormFields.map((field) => (
        <FormControl key={field.id}>
          <InputLabel htmlFor={field.id}>{field.label}</InputLabel>
          <Input
            id={field.id}
            type={field.type}
            {...register(field.id, { required: field.required })}
            aria-describedby={field.describedby}
            size="small"
            endAdornment={
              field.id === 'password' && (
                <InputAdornment position="end">
                  <Button variant="text" size="small" onClick={handleClickOpen}>
                    Reset password?
                  </Button>
                </InputAdornment>
              )
            }
          />
          <HelperTextContainer id={`${field.id}-helper-text`}>
            {errors[field.id] && <span>{errors[field.id]?.message}</span>}
          </HelperTextContainer>
          <ResetPassword
            setOpen={setOpen}
            open={open}
            handleClose={handleClose}
          />
        </FormControl>
      ))}
    </>
  );
};

export default LoginOtherFields;
