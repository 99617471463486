import { useCallback } from 'react';
import { Button, FormControl, Input, InputLabel } from '@mui/material';
import { useFormContext } from 'react-hook-form';

import { useHandleRequestOTP } from '@pages/common/Login/hooks';
import HelperTextContainer from '@components/HelperTextContainer';
import { type LoginSchema } from '@/zod_schemas';

const LoginOTPField = () => {
  const handleRequestOtp = useHandleRequestOTP();
  const {
    register,
    trigger,
    getValues,
    formState: { errors },
  } = useFormContext<LoginSchema>();

  const handleClickRequestOTP = useCallback(async () => {
    const fieldsState = await Promise.all([
      trigger('email'),
      trigger('password'),
    ]);

    if (fieldsState.some((v) => v === false)) {
      return;
    }

    await handleRequestOtp(getValues('email'), getValues('password'));
  }, [getValues, handleRequestOtp, trigger]);

  return (
    <FormControl>
      <InputLabel htmlFor="otp">One-Time Password</InputLabel>
      <Input
        id="otp"
        type="text"
        {...register('otp', { required: true })}
        aria-describedby="otp field"
        size="small"
        fullWidth
        endAdornment={
          <Button variant="text" size="small" onClick={handleClickRequestOTP}>
            Send
          </Button>
        }
      />
      <HelperTextContainer id="otp-helper-text">
        {errors.otp && <span>{errors.otp.message}</span>}
      </HelperTextContainer>
    </FormControl>
  );
};

export default LoginOTPField;
