import { useCallback } from 'react';

import { zodResolver } from '@hookform/resolvers/zod';
import { type SubmitHandler, useForm } from 'react-hook-form';
import {
  Button,
  FormControl,
  Input,
  InputLabel,
  Stack,
  Typography,
} from '@mui/material';
import { type AxiosError, isAxiosError } from 'axios';
import { enqueueSnackbar } from 'notistack';

import HelperTextContainer from '@components/HelperTextContainer';
import logo from '@assets/logo.svg';
import {
  RegisterFormHeader,
  StyledRegisterForm,
} from '@pages/common/Register/StyledComponents';
import { useRegisterMutation } from '@api/auth';
import { sentryCaptureException } from '@utils/sentry';
import { APIValidationError } from '@api/types';
import { useNavigate } from 'react-router-dom';
import { type RegisterSchema, registerSchema } from '@/zod_schemas';

const RegisterForm = () => {
  const navigate = useNavigate();
  const registerMutation = useRegisterMutation();

  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm<RegisterSchema>({
    resolver: zodResolver(registerSchema),
  });
  const onSubmit: SubmitHandler<RegisterSchema> = useCallback(
    async (data) => {
      try {
        await registerMutation.mutateAsync(data);
        setTimeout(() => {
          enqueueSnackbar('Registration successful', {
            variant: 'success',
          });
          navigate('/login');
        }, 2000);
      } catch (e) {
        if (isAxiosError(e) && e.response?.status === 400) {
          const castedError = e as AxiosError<APIValidationError>;

          Object.entries(castedError.response?.data || {}).forEach(
            ([key, value]) => {
              setError(key as keyof RegisterSchema, {
                type: castedError.code || 'unknown',
                message: value[0] as string,
              });
            },
          );

          return;
        }

        enqueueSnackbar('Something went wrong', {
          variant: 'error',
        });
        sentryCaptureException(e as Error);
      }
    },
    [registerMutation, setError, navigate],
  );

  return (
    <StyledRegisterForm onSubmit={handleSubmit(onSubmit)}>
      <Stack
        sx={{
          padding: '5rem',
          gap: '20px',
        }}
      >
        <RegisterFormHeader>
          <img src={logo} alt="logo" />
          <Typography variant="h5">SYNAPSYS</Typography>
          <Typography variant="overline">Create an account</Typography>
        </RegisterFormHeader>
        <FormControl>
          <InputLabel htmlFor="first_name">First Name</InputLabel>
          <Input
            id="first_name"
            type="text"
            {...register('first_name', {
              required: true,
            })}
            aria-describedby="first name field"
            size="medium"
            fullWidth
          />
          <HelperTextContainer id="first-name-helper-text">
            {errors.first_name && <span>{errors.first_name.message}</span>}
          </HelperTextContainer>
        </FormControl>
        <FormControl>
          <InputLabel htmlFor="last_name">Last Name</InputLabel>
          <Input
            id="last_name"
            type="text"
            {...register('last_name', {
              required: true,
            })}
            aria-describedby="last name field"
            size="medium"
            fullWidth
          />
          <HelperTextContainer id="last-name-helper-text">
            {errors.last_name && <span>{errors.last_name.message}</span>}
          </HelperTextContainer>
        </FormControl>
        <FormControl>
          <InputLabel htmlFor="email">E-Mail</InputLabel>
          <Input
            id="email"
            type="email"
            {...register('email', {
              required: true,
            })}
            aria-describedby="email field"
            size="medium"
            fullWidth
          />
          <HelperTextContainer id="email-helper-text">
            {errors.email && <span>{errors.email.message}</span>}
          </HelperTextContainer>
        </FormControl>
        <FormControl>
          <InputLabel htmlFor="password">Password</InputLabel>
          <Input
            id="password"
            type="password"
            {...register('password', {
              required: true,
            })}
            aria-describedby="password field"
            size="medium"
            fullWidth
          />
          <HelperTextContainer id="password-helper-text">
            {errors.password && <span>{errors.password.message}</span>}
          </HelperTextContainer>
        </FormControl>
        <Button type="submit" variant="contained">
          Register
        </Button>
      </Stack>
    </StyledRegisterForm>
  );
};
export default RegisterForm;
