import { z } from 'zod';

export const registerSchema = z.object({
  first_name: z.string().nonempty({ message: 'First name is required' }),
  last_name: z.string().nonempty({ message: 'Last name is required' }),
  email: z.string().nonempty({ message: 'Email is required' }),
  password: z.string().nonempty({ message: 'Password is required' }),
});

// export const resetPasswordSchema = z.object({
//   email: z.string().nonempty({ message: 'Email is required' }),
//   password: z.string().nonempty({ message: 'Password is required' }),
//   confirm_password: z.string().nonempty({ message: 'Password is required' }),
//   token: z.string(),
// });

// Define the schema
export const resetPasswordSchema = z
  .object({
    // email: z
    //   .string()
    //   .email({ message: 'Invalid email address' })
    //   .nonempty({ message: 'Email is required' }),
    new_password: z
      .string()
      .min(6, { message: 'Password must be at least 6 characters long' })
      .nonempty({ message: 'Password is required' }),
    confirm_password: z
      .string()
      .nonempty({ message: 'Please confirm your password' }),
  })
  .refine((data) => data.new_password === data.confirm_password, {
    message: 'Passwords does not match',
    path: ['confirm_password'], // This is where the error will appear
  });

export const emailValidationSchema = z.object({
  email: z.string().nonempty({ message: 'E-Mail is required' }),
});

export const loginSchema = z.object({
  email: z.string().nonempty({ message: 'E-Mail is required' }),
  password: z.string().nonempty({ message: 'Password is required' }),
  otp: z.string().nonempty({ message: 'One-Time password is required' }),
  remember: z.boolean().optional(),
});

export const logoutSchema = z.object({
  token: z.string(),
});

const sampleGender = z.enum(['male', 'female', 'unknown']);

const sampleType = z.enum(['unpaired_fastq', 'paired_fastq', 'bam']);

const sampleFileType = z.enum(['fastq_forward', 'fastq_reverse', 'bam']);

const sampleFile = z.object({
  file_path: z.string(),
  // .min(1, { message: 'Please select file' }),

  file_type: sampleFileType,
});

export const sampleSchema = z.object({
  id: z.string().optional(),
  sample_id: z.string().nonempty({ message: 'Sample id is required' }),
  gender: sampleGender,
  sample_type: sampleType,
  files: z.array(sampleFile),
  isNew: z.boolean().optional(),

  inputValue: z.string().optional(),
  // .nonempty({ message: 'At least one file is required' }),
});

export const sampleIDSchema = z.object({
  id: z.string().optional(),
});

const experimentType = z.enum(['single', 'trio', 'custom']);

const experimentStatus = z.enum([
  'pending',
  'completed',
  'in_progress',
  'failed',
  'waiting',
]);

export const experimentSchema = z.object({
  id: z.string().optional(),
  name: z.string().nonempty({ message: 'Experiment name is required' }),
  description: z.string().optional(),
  pipeline_configs: z.array(z.string()),
  // .min(1, { message: 'Please select at least one pipeline' }),
  experiment_type: experimentType,
  status: experimentStatus.optional(), // coming from the server and is read-only
  experiment_id: z.string().optional(), // coming from the server and is read-only
  samples: z.array(sampleSchema).nonempty({
    message: 'At least one sample is required',
  }),
});

export const experimentSchemaID = z.object({
  id: z.string().optional(),
  name: z.string().nonempty({ message: 'Experiment name is required' }),
  description: z.string().optional(),
  pipeline_configs: z.array(z.string()).optional(),
  // .min(1, { message: 'Please select at least one pipeline' }),
  experiment_type: experimentType,
  status: experimentStatus.optional(), // coming from the server and is read-only
  experiment_id: z.string().optional(), // coming from the server and is read-only
  samples: z.array(z.string()).optional(),
});

export const experimentBatchSchema = z.object({
  id: z.string().optional(),
  name: z.string().optional(),
  description: z.string().optional(),
  experiment_type: experimentType.optional(),
  status: experimentStatus.optional(), // coming from the server and is read-only
  experiment_id: z.string().optional(), // coming from the server and is read-only
  samples: z
    .array(
      z.object({
        sample_id: z.string().min(1, { message: 'Sample ID is required' }),
      }),
    )
    .nonempty({
      message: 'At least one sample is required',
    }),
});

export const projectSchema = z.object({
  id: z.string().optional(),
  name: z.string().min(1, { message: 'Project name is required' }),
  description: z.string().optional(),
  project_type: z.string(),
  experiments: z.array(experimentSchema).nonempty({
    message: 'At least one experiment is required',
  }),
});

export const projectSchemaID = z.object({
  id: z.string().optional(),
  name: z.string().min(1, { message: 'Project name is required' }),
  description: z.string().optional(),
  project_type: z.string(),
  experiments: z.array(experimentSchemaID).nonempty({
    message: 'At least one experiment is required',
  }),
});

export const optionsConfigSchema = z.object({
  id: z.string(),
  value: z.union([z.string(), z.number()]),
});

export const pipelineConfigSchema = z
  .object({
    id: z.string().optional(),
    allowEmpty: z.boolean(),
    name: z.string(),
    type: z.enum(['textfield', 'displayfield', 'dropdown']),
    value: z.union([
      z.string(),
      z.number(),
      z.array(z.union([z.string(), z.number()])),
    ]),
    input_type: z.enum(['string', 'number']),
    multiple: z.boolean().optional(), // only required for dropdown
    options: z.array(optionsConfigSchema).nonempty({
      message: 'Please add sample',
    }),
  })
  .superRefine((data, ctx) => {
    // Validate input optional
    if (
      data.allowEmpty === false &&
      (data.value === null || data.value === undefined || data.value === '')
    ) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'Please fields this input fields',
        path: ['value'],
      });
    }

    // Validate the data based on the pipeline config
    if (data.input_type === 'number' && typeof data.value !== 'number') {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'Invalid value. Please use number',
        path: ['value'],
      });
    } else if (data.input_type === 'string' && typeof data.value !== 'string') {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'Invalid value. Please use string',
        path: ['value'],
      });
    }
  });

export const pipelineSchema = z.object({
  id: z.string(),
  main_pipelineID: z.string().optional(),
  name: z.string().min(1, { message: 'Pipeline name is required' }),
  description: z
    .string()
    .min(1, { message: 'Pipeline description is required' }),
  created_at: z.string().optional(),
  updated_at: z.string().optional(),
  configs: z.array(pipelineConfigSchema).optional(),
});

export const sampleBatch = z.object({
  id: z.string().optional(),
  name: z.string().nonempty({ message: 'Sample ID is required' }),
  experiment_type: experimentType,
  samples: z
    .array(
      z.object({
        sample_id: z.string().nonempty({ message: 'Sample id is required' }),
      }),
    )
    .nonempty({
      message: 'At least one sample is required',
    }),
});

export const batchSchema = z.object({
  id: z.string().optional(),
  name: z.string().min(1, { message: 'Batch name is required' }),
  description: z.string().optional(),
  project_type: z.string(),
  bam_file: z.string().optional(),
  experiments: z.array(experimentSchema).nonempty({
    message: 'Please add sample',
  }),
});

const patientGender = z.enum(['male', 'female', 'unknown']);

export const additionalSchema = z.object({
  id: z.string().optional(),
  additional_sample_id2: z.string().optional(),
  additional_dob2: z.coerce.date().min(new Date(0)),
  additional_ethnicity2: z.array(z.string()),
  additional_symptomatic2: z.string().optional(),
  additional_specimen_date2: z.coerce.date().min(new Date(0)),
});

export const hpoSchema = z.object({
  id: z.string().optional(),
  hpo_id: z.string().optional(),
  name: z.string().optional(),
  definition: z.string().optional(),
  comment: z.string().optional(),
  descendant_count: z.number().nullable(),
  synonyms: z.array(z.string()),
  xrefs: z.array(z.string()),
  translations: z.string().nullable(),
  created_at: z.string().optional(),
  updated_at: z.string().optional(),
  created_by: z.string().optional(),
});

export const ethnicitySchema = z.array(
  z.object({
    id: z.number(),
    name: z.string().min(3, { message: 'Not enough characters' }),
    description: z.string().optional(),
  }),
);

export const patientSchema = z.object({
  // Patient Details
  name: z.string().min(3, { message: 'Patient name is required' }),
  id: z.string().optional(),
  identification: z
    .string()
    .min(1, { message: 'Required' })
    .refine((val) => val.length >= 3, {
      message: 'Not enough characters',
    }),
  ethnicity: z.array(z.string()),
  date_of_birth: z
    .string()
    .refine(
      (str) =>
        /^\d{4}-\d{2}-\d{2}$/.test(str) &&
        new Date(str).toISOString().startsWith(str),
      {
        message: 'Required',
      },
    ),
  gender: patientGender,

  // HPO Details
  // hpo_ids: z.array(hpoSchema).optional(),
  samples: z.array(sampleSchema).optional(),
  hpo_ids: z.array(z.string()).optional(),
  sample_ids: z.array(z.string()).optional(),
});

export type RegisterSchema = z.infer<typeof registerSchema>;
export type ResetSchema = z.infer<typeof registerSchema>;
export type LoginSchema = z.infer<typeof loginSchema>;
export type LogoutSchema = z.infer<typeof logoutSchema>;
export type RequestOtpSchema = Omit<LoginSchema, 'otp' | 'remember'>;
export type ProjectSchema = z.infer<typeof projectSchema>;
export type ProjectSchemaID = z.infer<typeof projectSchemaID>;
export type BatchSchema = z.infer<typeof batchSchema>;
export type SampleBatchSchema = z.infer<typeof sampleBatch>;
export type PatientSchema = z.infer<typeof patientSchema>;
export type EthnicitySchema = z.infer<typeof ethnicitySchema>;
export type ExperimentTypeSchema = z.infer<typeof experimentType>;
export type ExperimentSchemaID = z.infer<typeof experimentSchemaID>;
export type ExperimentSchema = z.infer<typeof experimentSchema>;
export type PipelineConfigSchema = z.infer<typeof pipelineConfigSchema>;
export type SampleSchema = z.infer<typeof sampleSchema>;
export type SampleIDSchema = z.infer<typeof sampleIDSchema>;
export type OptionsConfigSchema = z.infer<typeof optionsConfigSchema>;
export type SampleGenderSchema = z.infer<typeof sampleGender>;
export type SampleFileTypeSchema = z.infer<typeof sampleType>;
export type SampleFileSchema = z.infer<typeof sampleFile>;
export type ExperimentStatusSchema = z.infer<typeof experimentStatus>;
export type PipelineSchema = z.infer<typeof pipelineSchema>;
export type HPOSchema = z.infer<typeof hpoSchema>;
export type EmailValidationSchema = z.infer<typeof emailValidationSchema>;
export type ResetPasswordSchema = z.infer<typeof resetPasswordSchema>;
