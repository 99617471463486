import { useCallback } from 'react';

import { zodResolver } from '@hookform/resolvers/zod';
import { type SubmitHandler, useForm } from 'react-hook-form';
import {
  Button,
  FormControl,
  Input,
  InputLabel,
  Stack,
  Typography,
} from '@mui/material';
import { type AxiosError, isAxiosError } from 'axios';
import { enqueueSnackbar } from 'notistack';

import HelperTextContainer from '@components/HelperTextContainer';
import logo from '@assets/logo.svg';
import {
  RegisterFormHeader,
  StyledRegisterForm,
} from '@pages/common/Register/StyledComponents';
import { sentryCaptureException } from '@utils/sentry';
import { APIValidationError } from '@api/types';
import { useNavigate, useParams } from 'react-router-dom';
import { resetPasswordSchema, type ResetPasswordSchema } from '@/zod_schemas';
import { useResetPasswordMutation } from '@/api/auth/auth';

const ResetPasswordForm = () => {
  const navigate = useNavigate();
  const { uniqId } = useParams();
  const resetPasswordMutation = useResetPasswordMutation(uniqId!);

  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm<ResetPasswordSchema>({
    resolver: zodResolver(resetPasswordSchema),
  });

  // const onSubmit: SubmitHandler<ResetPasswordSchema> = () => {
  //   console.log('asd', uniqId);
  // };

  const onSubmit: SubmitHandler<ResetPasswordSchema> = useCallback(
    async (data) => {
      try {
        await resetPasswordMutation.mutateAsync(data);
        setTimeout(() => {
          enqueueSnackbar('Reset password successful', {
            variant: 'success',
          });
          navigate('/login');
        }, 2000);
      } catch (e) {
        if (isAxiosError(e) && e.response?.status === 400) {
          const castedError = e as AxiosError<APIValidationError>;

          Object.entries(castedError.response?.data || {}).forEach(
            ([key, value]) => {
              setError(key as keyof ResetPasswordSchema, {
                type: castedError.code || 'unknown',
                message: value[0] as string,
              });
            },
          );

          return;
        }

        enqueueSnackbar('Something went wrong', {
          variant: 'error',
        });
        sentryCaptureException(e as Error);
      }
    },
    [resetPasswordMutation, setError, navigate],
  );

  return (
    <StyledRegisterForm onSubmit={handleSubmit(onSubmit)}>
      <Stack
        sx={{
          padding: '5rem',
          gap: '20px',
        }}
      >
        <RegisterFormHeader>
          <img src={logo} alt="logo" />
          <Typography variant="h5">SYNAPSYS</Typography>
          <Typography variant="overline">Reset password</Typography>
        </RegisterFormHeader>
        <FormControl>
          <InputLabel htmlFor="password">New password</InputLabel>
          <Input
            id="password"
            type="password"
            {...register('new_password', {
              required: true,
            })}
            aria-describedby="new password field"
            size="medium"
            fullWidth
          />
          <HelperTextContainer id="new-password-helper-text">
            {errors.new_password && <span>{errors.new_password.message}</span>}
          </HelperTextContainer>
        </FormControl>
        <FormControl>
          <InputLabel htmlFor="confirm-password">Confirm password</InputLabel>
          <Input
            id="confirm-password"
            type="password"
            {...register('confirm_password', {
              required: true,
            })}
            aria-describedby="confirm password field"
            size="medium"
            fullWidth
          />
          <HelperTextContainer id="confirm-password-helper-text">
            {errors.confirm_password && (
              <span>{errors.confirm_password.message}</span>
            )}
          </HelperTextContainer>
        </FormControl>
        <Button type="submit" variant="contained">
          Confirm
        </Button>
      </Stack>
    </StyledRegisterForm>
  );
};
export default ResetPasswordForm;
