import { type FieldArrayWithId } from 'react-hook-form';
import ExperimentForm from '@pages/common/Project/Forms/ExperimentForm';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  IconButton,
  Typography,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { ExperimentTypeSchema, ProjectSchema } from '@/zod_schemas';
import { useProjectContext } from '../../../FormContext/useProjectContext';

interface IExperimentsContainerUpdatedProps {
  changeExperimentType: (type: ExperimentTypeSchema, index: number) => void;
  fields: FieldArrayWithId<ProjectSchema, 'experiments'>[];
  removeExperiment: (index: number) => void;
  mode: 'create' | 'update';
  expType?: string;
  project_type?: string;
}

const ExperimentsContainerUpdated = ({
  changeExperimentType,
  fields,
  removeExperiment,
  project_type,
}: IExperimentsContainerUpdatedProps) => {
  const {
    register,
    control,
    setValue,
    formState: { errors },
  } = useProjectContext();

  const getExperiment = (
    index: number,
    isExpanded: boolean,
    id: string,
    experiment_id: string | undefined,
  ) => {
    alert('get experiement api');
    console.log('index', index);
    console.log('isExpanded', isExpanded);
    console.log('id', id);
    console.log('experiment_id', experiment_id);
  };

  return fields.map(({ id, experiment_id }, index) => (
    <>
      <Accordion
        disableGutters
        onChange={(_event, isExpanded) =>
          getExperiment(index, isExpanded, id, experiment_id)
        }
        sx={{
          backgroundColor: index % 2 === 0 ? '#f5f5f5' : '#e0f7fa',
          border: '1px solid #ccc',
          marginBottom: '8px',
          boxShadow: 'none',
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          sx={{
            backgroundColor: index % 2 === 0 ? '#e0e0e0' : '#b2ebf2',
            padding: '4px 8px',
            minHeight: '10px',
            '& .MuiAccordianSummary-content': {
              margin: '0px',
            },
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              width: '100%', // Ensure the box takes full width
            }}
          >
            <IconButton
              edge="end"
              color="error"
              onClick={(event) => {
                event.stopPropagation();
                removeExperiment(index);
              }}
              sx={{
                marginLeft: 'auto', // Push the button to the far right
              }}
            >
              <DeleteIcon />
            </IconButton>
            <Typography
              variant="body1"
              sx={{ flexGrow: 1, margin: '0 0 0 20px' }}
            >
              {project_type
                ? `${project_type} ${index + 1}`
                : `Experiment ${index + 1}`}
            </Typography>
          </Box>
        </AccordionSummary>
        <AccordionDetails sx={{ padding: '8px' }}>
          <Box sx={{ padding: '30px' }}>
            <ExperimentForm
              index={index}
              register={register}
              setValue={setValue}
              errors={errors}
              control={control}
              changeExperimentType={(type: ExperimentTypeSchema) =>
                changeExperimentType(type, index)
              }
            />
          </Box>
        </AccordionDetails>
      </Accordion>
      {/* <Section
        key={id}
        title={
          project_type
            ? `${project_type} ${index + 1}`
            : `Experiment ${index + 1}`
        }
        headerAction={
          <HeaderAction
            removeExperiment={() => removeExperiment(index)}
            mode={mode}
            experimentId={experiment_id}
          />
        }
      >
        <ExperimentForm
          index={index}
          register={register}
          setValue={setValue}
          errors={errors}
          control={control}
          changeExperimentType={(type: ExperimentTypeSchema) =>
            changeExperimentType(type, index)
          }
        />
      </Section> */}
    </>
  ));
};

export default ExperimentsContainerUpdated;
