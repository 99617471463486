import { useCallback } from 'react';
import { enqueueSnackbar } from 'notistack';
import { useNavigate, useParams } from 'react-router-dom';
import { SubmitHandler, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  useCreateProject,
  useGetProject,
  useUpdateProject,
} from '@api/project/project';
import { UseMutationResult } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';
import {
  projectSchema,
  SampleSchema,
  type ProjectSchema,
  ProjectSchemaID,
} from '@/zod_schemas';
import { useCreateSample } from '@/api/sample/sample';
import { APIError } from '@/api/types';

type ProjectPathParams = {
  projectId: string;
};

interface UseProjectFormHandlerReturn
  extends ReturnType<typeof useForm<ProjectSchema>> {
  mode: 'create' | 'update';
  projectId?: string;
}

type UseProjectFormHandler = () => UseProjectFormHandlerReturn;

export const useProjectFormHandler: UseProjectFormHandler = () => {
  const { projectId } = useParams<ProjectPathParams>();
  const { data } = useGetProject(projectId);

  const test = data
    ? {
        ...data,
        experiments: [
          {
            id: 'bc382208-0ccb-48dd-ae3d-e7806de77b08',
            created_by: {
              id: 'f7f42bcb-c2ac-405a-b164-936394ad8db5',
              first_name: 'muhammad',
              last_name: 'sukeri',
            },
            experiment_id: 'bc382208-0ccb-48dd-ae3d-e7806de77b08',
            group_id: 1,
            pipeline_configs: ['784ab8ec-3821-4c7b-8086-52c5564a206e'],
            parent_id: 'bc382208-0ccb-48dd-ae3d-e7806de77b08',
            name: 'exp name',
            created_at: '2024-09-12T07:29:40.138735Z',
            updated_at: '2024-09-12T07:29:40.138740Z',
            description: 'exp desc',
            experiment_type: 'custom',
            status: 'pending',
            active: true,
            result: null,
            samples: [
              {
                sample_id: 'muhammad',
                gender: 'male',
                sample_type: 'paired_fastq',
                files: [
                  {
                    file_path: 'asd',
                    file_type: 'fastq_forward',
                  },
                  {
                    file_path: 'asdxxx',
                    file_type: 'fastq_reverse',
                  },
                ],
              },
            ],
            project: '8a2f0c6a-6092-4290-96be-8d59f3d9ba36',
            pipeline_config: '784ab8ec-3821-4c7b-8086-52c5564a206e',
          },
        ],
      }
    : {};

  console.log('asdasdasd', test);
  const formMethods = useForm<ProjectSchema>({
    resolver: zodResolver(projectSchema),
    defaultValues: data || {},
  });

  return {
    ...formMethods,
    mode: projectId ? 'update' : 'create',
    projectId,
  };
};
// Typing for the createSample function
type CreateSampleType = UseMutationResult<
  AxiosResponse<SampleSchema[]>,
  APIError,
  SampleSchema[]
>;

// processSamples now takes the createSample mutation as an argument
async function processSamples(
  data: ProjectSchema,
  createSample: CreateSampleType,
) {
  const updatedExperiments = await Promise.all(
    data.experiments.map(async (experiment) => {
      const updatedSamples = await Promise.all(
        experiment.samples.map(async (sample) => {
          if (sample.id === '') {
            try {
              // Await the mutateAsync call from the passed createSample
              const response = await createSample.mutateAsync([sample]);
              const sampleResponse = response.data;

              enqueueSnackbar('Samples created successfully', {
                variant: 'success',
              });

              // Return the newly created sample with the ID from the response
              return sampleResponse[0].id;
            } catch (error) {
              // Handle the error when the API call fails
              enqueueSnackbar('Failed to create sample', {
                variant: 'error',
              });

              // Optionally, return the sample id for existing sample
              return sample.id;
            }
          }

          // If the sample already has an ID, return it unchanged
          return sample.id;
        }),
      );

      // Return the updated experiment with the new or unchanged samples
      return { ...experiment, samples: updatedSamples };
    }),
  );

  return { ...data, experiments: updatedExperiments }; // Return the updated data structure
}

export const useCreateOrUpdateProject = (
  mode: 'create' | 'update',
  projectId?: string,
) => {
  const navigate = useNavigate();
  const createProject = useCreateProject();
  const updateProject = useUpdateProject();
  const createSample = useCreateSample();

  const onSubmit: SubmitHandler<ProjectSchema> = useCallback(
    (data) =>
      new Promise<void>((resolve, reject) => {
        processSamples(data, createSample)
          .then((updatedData) => {
            const finalProject = updatedData as ProjectSchemaID;
            if (mode === 'create') {
              createProject
                .mutateAsync(finalProject)
                .then(({ data: projectData }) => {
                  enqueueSnackbar('Project created successfully', {
                    variant: 'success',
                  });
                  navigate(`/projects/${projectData.id}`);
                  resolve();
                })
                .catch((error) => {
                  console.error(error);
                  enqueueSnackbar('Failed to create project', {
                    variant: 'error',
                  });
                  reject(error);
                });
            } else {
              if (!projectId) {
                reject(
                  new Error('Project ID is required. Failed to update project'),
                );
                enqueueSnackbar('Unknown error occurred', { variant: 'error' });
                return;
              }

              updateProject
                .mutateAsync({ payload: finalProject, id: projectId })
                .then(() => {
                  enqueueSnackbar('Project updated successfully', {
                    variant: 'success',
                  });
                  navigate(`/projects/${data.id}`);
                  resolve();
                })
                .catch((error) => {
                  console.error(error);
                  enqueueSnackbar('Failed to update project', {
                    variant: 'error',
                  });
                  reject(error);
                });
            }
          })
          .catch((error) => {
            enqueueSnackbar(
              'Create project failed. Unable to process sample.',
              { variant: 'error' },
            );
            reject(error);
          });
      }),
    [createProject, updateProject, createSample, navigate, projectId, mode],
  );
  // const onSubmit: SubmitHandler<ProjectSchema> = (data) => {
  //   console.log(data);
  // };

  return onSubmit;
};

export const useEditProjectFormHandler: UseProjectFormHandler = () => {
  const { projectId } = useParams<ProjectPathParams>();
  const { data } = useGetProject(projectId);
  console.log('muhammad', data, projectId);
  const test = data
    ? {
        ...data,
        experiments: [
          {
            id: 'bc382208-0ccb-48dd-ae3d-e7806de77b08',
            experiment_id: 'bc382208-0ccb-48dd-ae3d-e7806de77b08',
          },
          {
            id: '12',
            experiment_id: '12',
          },
          {
            id: 'xxx',
            experiment_id: 'xxx',
          },
          {
            id: 'fff',
            experiment_id: 'fff',
          },
        ],
      }
    : {};
  const formMethods = useForm<ProjectSchema>({
    resolver: zodResolver(projectSchema),
    defaultValues: test || {},
  });

  console.log('test', test);

  return {
    ...formMethods,
    mode: projectId ? 'update' : 'create',
    projectId,
  };
};

export const useEditProject = (
  mode: 'create' | 'update',
  projectId?: string,
) => {
  const onSubmit: SubmitHandler<ProjectSchema> = () => ({
    mode,
    projectId,
  });

  return onSubmit;
};
