import { Box, Button } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { type PipelineList, useGetPipeline } from '@api/pipeline';
import { useNavigate } from 'react-router-dom';
import { usePipelineFormContext } from '@pages/common/Administrator/Pipeline/usePipelineFormContext'; // Adjust the import path as needed
import { formatDate } from '@/utils/dateUtils';

const PipelineListData = () => {
  const navigate = useNavigate();
  const { data } = useGetPipeline();
  const pipelines = data as PipelineList;
  const { setDataPipeline } = usePipelineFormContext();

  const columns: GridColDef<(typeof pipelines)[number]>[] = [
    {
      field: 'name',
      headerName: 'Name',
      flex: 1,
      editable: true,
    },
    {
      field: 'description',
      headerName: 'Description',
      flex: 1,
      editable: true,
    },
    {
      field: 'created_at',
      headerName: 'Created at',
      // type: 'number',
      flex: 1,
      // editable: true,
      valueGetter: (value) => {
        const readableDate = formatDate(value);
        return readableDate;
      },
    },
    {
      field: 'updated_at',
      headerName: 'Updated at',
      description: 'This column has a value getter and is not sortable.',
      sortable: false,
      flex: 1,
      valueGetter: (value) => {
        const readableDate = formatDate(value);
        return readableDate;
      },
    },
    {
      field: 'actions',
      headerName: 'Actions',
      sortable: false,
      flex: 0.8,
      renderCell: (params) => (
        <Box>
          <Button
            variant="contained"
            color="primary"
            size="small"
            onClick={() => {
              setDataPipeline({});
              navigate(`/administrator/pipeline/${params.row.id}`);
            }}
          >
            Edit
          </Button>
          <Button
            variant="contained"
            color="secondary"
            size="small"
            onClick={() => {
              setDataPipeline({
                mainPipelineID: params.row.id,
              });
              navigate(
                `/administrator/pipeline/create?duplicate=${params.row.id}`,
              );
            }}
            style={{ marginLeft: 8 }}
          >
            Duplicate
          </Button>
        </Box>
      ),
    },
  ];

  return (
    <Box>
      <DataGrid
        rows={pipelines}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 5,
            },
          },
        }}
        pageSizeOptions={[5]}
        checkboxSelection
        disableRowSelectionOnClick
      />
    </Box>
  );
};

export default PipelineListData;
