import { Typography, Grid } from '@mui/material';

import { ProjectListHeaderContainer } from '@pages/common/Project/Components/StyledComponents';

const ProjectsListHeader = () => (
  <ProjectListHeaderContainer square>
    <Grid container>
      <Grid item xs={12} sm={6} md={4} lg={3}>
        <Typography variant="caption">Name</Typography>
      </Grid>
      <Grid item sm={6} md={4} lg={3} display={{ xs: 'none', sm: 'block' }}>
        <Typography variant="caption">Description</Typography>
      </Grid>
      <Grid item md={4} lg={3} display={{ xs: 'none', md: 'block' }}>
        <Typography variant="caption">Experiments</Typography>
      </Grid>
      <Grid item lg={3} display={{ xs: 'none', lg: 'block' }}>
        <Typography variant="caption">Created By</Typography>
      </Grid>
    </Grid>
  </ProjectListHeaderContainer>
);

export default ProjectsListHeader;
