import { type AxiosResponse } from 'axios';
import { useMutation, useSuspenseQuery } from '@tanstack/react-query';

import { APIError, ResourceWithId } from '@api/types';
import { post, get, put } from '@api/httpClient';
import { type ProjectListResponse } from '@api/project/types';
import queryClient from '@api/queryClient';
import { ProjectSchema, type ProjectSchemaID } from '@/zod_schemas';

const QUERY_KEYS = {
  PROJECTS: ['projects'],
  PROJECT: (projectId: string) => ['projects', { id: projectId }],
};

export const useCreateProject = () =>
  useMutation<AxiosResponse<ProjectSchemaID>, APIError, ProjectSchemaID>({
    mutationFn: (project) =>
      post<ProjectSchemaID>('/projects/', { ...project }),
  });

export const useUpdateProject = () =>
  useMutation<
    AxiosResponse<ProjectSchemaID>,
    APIError,
    ResourceWithId<ProjectSchemaID>
  >({
    mutationFn: ({ id, payload }) =>
      put<ProjectSchemaID>(`/projects/${id}/`, { ...payload }),
    onSuccess: ({ data: { id } }) => {
      if (!id) return;

      queryClient.invalidateQueries({
        queryKey: QUERY_KEYS.PROJECTS,
      });
    },
  });

export const useListProjects = () =>
  useSuspenseQuery<ProjectListResponse>({
    queryKey: QUERY_KEYS.PROJECTS,
    queryFn: async () => {
      const resp = await get<ProjectListResponse>('/projects/');
      return resp.data;
    },
  });

export const useGetProject = (projectId?: string) =>
  useSuspenseQuery<ProjectSchema>({
    queryKey: QUERY_KEYS.PROJECT(projectId || ''),
    queryFn: async () => {
      if (!projectId) return {};

      const resp = await get(`/projects/${projectId}/`);
      return resp.data;
    },
  });
