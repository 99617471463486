import { Grid, Stack, Typography } from '@mui/material';

import { ProjectListCreatedByUser } from '@api/project';
import { ProjectCreatedByAvatar } from '@pages/common/Project/Components/StyledComponents';
import { stringToColor } from '@utils/stringToColor';

interface IProjectListItemProps {
  name: string;
  description?: string;
  createdBy: ProjectListCreatedByUser;
  experimentCount: number;
}

const ProjectListItem = ({
  createdBy,
  description,
  name,
  experimentCount,
}: IProjectListItemProps) => (
  <Grid container sx={{ alignItems: 'center' }}>
    <Grid item xs={12} sm={6} md={4} lg={3}>
      <Typography variant="caption">{name}</Typography>
    </Grid>
    <Grid item sm={6} md={4} lg={3} display={{ xs: 'none', sm: 'block' }}>
      <Typography variant="caption">{description}</Typography>
    </Grid>
    <Grid item md={4} lg={3} display={{ xs: 'none', md: 'block' }}>
      <Typography variant="caption">{experimentCount}</Typography>
    </Grid>
    <Grid item lg={3} display={{ xs: 'none', lg: 'block' }}>
      <Stack direction="row" spacing={1} sx={{ alignItems: 'center' }}>
        <ProjectCreatedByAvatar
          sx={{
            backgroundColor: stringToColor(
              `${createdBy.first_name}${createdBy.last_name}`,
            ),
          }}
        >
          {createdBy.first_name[0]}
          {createdBy.last_name[0]}
        </ProjectCreatedByAvatar>
        <Typography variant="caption">
          {createdBy.first_name} {createdBy.last_name}
        </Typography>
      </Stack>
    </Grid>
  </Grid>
);

export default ProjectListItem;
