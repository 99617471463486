import { useCallback } from 'react';
import { enqueueSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import { type SubmitHandler } from 'react-hook-form';
import { isAxiosError } from 'axios';

import { useLoginMutation, useRequestOtpMutation } from '@api/auth';
import { sentryCaptureException } from '@utils/sentry';
import { type LoginSchema } from '@/zod_schemas';
import { useRequestPasswordMutation } from '@/api/auth/auth';

export const useHandleLogin: () => SubmitHandler<LoginSchema> = () => {
  const loginMutation = useLoginMutation();
  const navigate = useNavigate();

  return useCallback(
    async (data) => {
      try {
        await loginMutation.mutateAsync(data);
        enqueueSnackbar('Login successful', { variant: 'success' });
        navigate('/');
      } catch (e) {
        if (isAxiosError(e) && e.response?.status === 401) {
          enqueueSnackbar('Invalid email, password or OTP', {
            variant: 'error',
          });

          return;
        }

        enqueueSnackbar('Login failed', { variant: 'error' });
        sentryCaptureException(e as Error);
      }
    },
    [loginMutation, navigate],
  );
};

export const useHandleRequestOTP = () => {
  const requestOtp = useRequestOtpMutation();

  return useCallback(
    async (email: string, password: string) => {
      try {
        await requestOtp.mutateAsync({
          email,
          password,
        });

        enqueueSnackbar('OTP sent to your email', { variant: 'success' });
      } catch (e) {
        if (isAxiosError(e) && e.response?.status === 401) {
          enqueueSnackbar('Invalid email or password', { variant: 'error' });

          return;
        }

        enqueueSnackbar('Something went wrong', { variant: 'error' });
        sentryCaptureException(e as Error);
      }
    },
    [requestOtp],
  );
};

export const useHandleResetPassword = () => {
  const resetPassword = useRequestPasswordMutation();

  return useCallback(
    async (email: string) => {
      try {
        await resetPassword.mutateAsync({
          email,
        });

        enqueueSnackbar('Please check your email', { variant: 'success' });
      } catch (e) {
        if (isAxiosError(e)) {
          if (e.response?.status === 401 || e.response?.status === 404) {
            enqueueSnackbar('Invalid email or password', { variant: 'error' });
            return;
          }
        }

        enqueueSnackbar('Something went wrong', { variant: 'error' });
        sentryCaptureException(e as Error);
      }
    },
    [resetPassword],
  );
};
