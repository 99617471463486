import { createContext } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { ProjectSchema } from '@/zod_schemas';

// Define the type of your ProjectContext
interface ProjectContextType extends UseFormReturn<ProjectSchema> {
  mode: 'create' | 'update';
  projectId?: string;
}

// Create and export ProjectContext
export const ProjectContext = createContext<ProjectContextType | undefined>(
  undefined,
);
