import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Button } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { useGetPatient } from '@/api/patient';
import { PatientSchema } from '@/zod_schemas';

const PatientList = () => {
  // For now we using mock data. To continue dev before waiting API to complete.
  // This MockData soon will be replace with actual API.
  const { data } = useGetPatient();
  const [patientData, setPatientData] = useState<PatientSchema[]>([]);

  const navigate = useNavigate();

  useEffect(() => {
    // eslint-disable-next-line max-len
    // Normalize data to array. This API might return set of patient(array) or single patient(object)
    if (data) {
      const normalizedData = Array.isArray(data) ? data : [data];
      setPatientData(normalizedData);
    }
  }, [data]);

  const columns: GridColDef<PatientSchema>[] = [
    {
      field: 'name',
      headerName: 'Name',
      flex: 1,
    },
    {
      field: 'identification',
      headerName: 'Identification',
      flex: 1,
    },
    {
      field: 'ethnicity',
      headerName: 'Ethnicity',
      flex: 1,
    },
    {
      field: 'date_of_birth',
      headerName: 'Date of Birth',
      flex: 1,
    },
    {
      field: 'gender',
      headerName: 'Gender',
      flex: 1,
    },
    {
      field: 'actions',
      headerName: 'Actions',
      sortable: false,
      flex: 0.8,
      renderCell: (params) => (
        <Box>
          <Button
            variant="contained"
            color="primary"
            size="small"
            onClick={() => {
              const { id } = params.row;
              navigate(`/patients/${id}`);
            }}
          >
            Edit
          </Button>
          <Button
            variant="contained"
            color="secondary"
            size="small"
            onClick={() => 'delete'}
            style={{ marginLeft: 8 }}
          >
            Delete
          </Button>
        </Box>
      ),
    },
  ];

  return (
    <Box>
      <DataGrid
        rows={patientData}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 5,
            },
          },
        }}
        pageSizeOptions={[5]}
        checkboxSelection
        disableRowSelectionOnClick
      />
    </Box>
  );
};

export default PatientList;
