import { ReactNode } from 'react';
import { ProjectContext } from './ProjectContext'; // Import the context from the context file
import { useProjectFormHandler } from '../hooks';

export const ProjectProvider = ({ children }: { children: ReactNode }) => {
  const projectFormHandler = useProjectFormHandler();

  return (
    <ProjectContext.Provider value={projectFormHandler}>
      {children}
    </ProjectContext.Provider>
  );
};
