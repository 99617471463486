import { Stack } from '@mui/material';
import { useFieldArray } from 'react-hook-form';
import { PlusIcon } from 'lucide-react';
import { Suspense, useCallback } from 'react';
import { AddExperimentButton, ProjectFormElement } from './StyledComponents';
import { ProjectProvider } from './FormContext/ProjectProvider';
import { ProjectHeader } from './Components';
import { ProjectForm } from './Forms';
import { defaultExperiment } from './Forms/defaults';
import HelperTextContainer from '@/components/HelperTextContainer';
import { useEditProject, useEditProjectFormHandler } from './hooks';
import { type ExperimentTypeSchema } from '@/zod_schemas';
import ExperimentsContainerUpdated from './Forms/Components/ExperimentContainer/ExperimentContainerUpdated';

const ProjectEdit = () => {
  const {
    handleSubmit,
    register,
    control,
    setValue,
    mode = 'update',
    projectId,
    formState: { errors, isSubmitting },
  } = useEditProjectFormHandler();

  const onSubmit = useEditProject(mode, projectId);

  const { append, fields, remove } = useFieldArray({
    control,
    name: 'experiments',
  });

  const changeExperimentType = useCallback(
    (type: ExperimentTypeSchema, index: number) => {
      setValue(`experiments.${index}.experiment_type`, type);
    },
    [setValue],
  );
  return (
    <ProjectProvider>
      <ProjectFormElement onSubmit={handleSubmit(onSubmit)}>
        <input type="hidden" value="default" {...register('project_type')} />
        <ProjectHeader control={control} isSubmitting={isSubmitting} />
        <ProjectForm register={register} errors={errors} control={control} />
        <Stack spacing={1} direction="row" alignItems="center">
          <AddExperimentButton
            variant="contained"
            color="primary"
            startIcon={<PlusIcon />}
            size="small"
            onClick={() => append(defaultExperiment)}
          >
            Add Experiment
          </AddExperimentButton>
          <HelperTextContainer>
            <span>
              {errors.experiments?.root && errors.experiments.root.message}
              {errors.experiments && errors.experiments.message}
            </span>
          </HelperTextContainer>
        </Stack>
        <ExperimentsContainerUpdated
          changeExperimentType={changeExperimentType}
          removeExperiment={(index: number) => remove(index)}
          fields={fields}
          mode={mode}
        />
      </ProjectFormElement>
    </ProjectProvider>
  );
};

const ProjectEditWithSuspense = () => (
  <Suspense fallback={<div>Loading...</div>}>
    <ProjectEdit />
  </Suspense>
);
export default ProjectEditWithSuspense;
