import { AxiosResponse } from 'axios';
import { useMutation, useSuspenseQuery } from '@tanstack/react-query';
import { get, post } from '@api/httpClient';
import { APIError } from '@api/types';
import { HPOSchema } from '@/zod_schemas';

type Category = 'term' | 'gene' | 'disease';

interface HPOResponse {
  terms: HPOSchema[];
  totalCount: number;
}

// Define the base URLs for each category
const BASE_URLS = {
  term: 'https://ontology.jax.org/api/hp/search',
  gene: 'https://ontology.jax.org/api/network/search/gene',
  disease: 'https://ontology.jax.org/api/network/search/disease',
};

const QUERY_KEYS = {
  HPOs: ['hpo'],
  HPO: (paramSearch: string, category: Category, limit: number) => [
    'q',
    { id: paramSearch, category, limit },
  ],
};

// Function to fetch data from a given URL
const fetchData = async (url: string, paramSearch: string, limit: number) => {
  const response = await get(`${url}?q=${paramSearch}&limit=${limit}`);
  return response.data;
};

// Update ID from API to hpo_id. Our backend using hpo_id instead of id
const transformIDs = (data: HPOResponse) => {
  const updatedTerms = data.terms.map((item) => ({
    ...item,
    hpo_id: item.id,
  }));

  return {
    ...data,
    terms: updatedTerms,
  };
};

// Custom hook to fetch HPO data based on the search query and category
export const useGetHpo = (
  paramSearch?: string,
  category: Category = 'term',
  limit = 1000,
) =>
  useSuspenseQuery({
    queryKey: QUERY_KEYS.HPO(paramSearch || '', category, limit),
    queryFn: async () => {
      if (!paramSearch) return {};

      // Fetch data from all three URLs in parallel
      const [termData, geneData, diseaseData] = await Promise.all([
        fetchData(BASE_URLS.term, paramSearch, limit),
        fetchData(BASE_URLS.gene, paramSearch, limit),
        fetchData(BASE_URLS.disease, paramSearch, limit),
      ]);

      const termDataUpdated = termData ? transformIDs(termData) : null;

      return {
        termData: termDataUpdated,
        geneData,
        diseaseData,
      };
    },
  });

export const useListHPO = () =>
  useSuspenseQuery<HPOSchema[]>({
    queryKey: QUERY_KEYS.HPOs,
    queryFn: async () => {
      const resp = await get<HPOSchema[]>('/hpo/');
      return resp.data;
    },
  });

export const useCreateHPO = () =>
  useMutation<AxiosResponse<HPOSchema[]>, APIError, HPOSchema[]>({
    mutationFn: (hpos) => post<HPOSchema[]>('/hpo/', hpos),
  });

const HPO_QUERY_KEYS = {
  PATIENTS_HPO: ['hpo'],
  PATIENT_HPO: (patientId: string) => ['hpo', { id: patientId }],
};

export const useGetPatientHPO = (patientId?: string) =>
  useSuspenseQuery<HPOSchema>({
    queryKey: HPO_QUERY_KEYS.PATIENT_HPO(patientId || ''),
    queryFn: async () => {
      if (!patientId) return {};
      const resp = await get(`/hpo/${patientId}/by_patient/`);
      return resp.data;
    },
  });
