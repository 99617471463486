import { Link } from 'react-router-dom';
import { Button, Checkbox, FormControlLabel, Typography } from '@mui/material';
import { FormProvider, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';

import LoginOtherFields from '@pages/common/Login/LoginOtherFields';
import LoginOTPField from '@pages/common/Login/LoginOTPField';
import {
  Form,
  LoginFormBottomContainer,
} from '@pages/common/Login/StyledComponents';
import HelperTextContainer from '@components/HelperTextContainer';
import { useHandleLogin } from '@pages/common/Login/hooks';
import { loginSchema, type LoginSchema } from '@/zod_schemas';

const LoginForm = () => {
  const handleLogin = useHandleLogin();
  const { ...formMethods } = useForm<LoginSchema>({
    resolver: zodResolver(loginSchema),
  });

  return (
    <FormProvider {...formMethods}>
      <Typography
        variant="overline"
        sx={{ fontSize: 'larger', display: 'flex', justifyContent: 'center' }}
        gutterBottom
      >
        Login to Synapsys
      </Typography>
      <Form onSubmit={formMethods.handleSubmit(handleLogin)}>
        <LoginOtherFields />
        <LoginOTPField />
        <Button type="submit" variant="contained">
          Login
        </Button>
        <LoginFormBottomContainer>
          <FormControlLabel
            control={
              <Checkbox
                defaultChecked
                id="remember-me"
                {...formMethods.register('remember', { required: false })}
                aria-describedby="remember field"
                size="small"
              />
            }
            label="Remember Me"
          />
          <HelperTextContainer id="remember-helper-text">
            {formMethods.formState.errors.remember && (
              <span>{formMethods.formState.errors.remember.message}</span>
            )}
          </HelperTextContainer>
          <Link to="/register">Create an Account</Link>
        </LoginFormBottomContainer>
      </Form>
    </FormProvider>
  );
};

export default LoginForm;
