// src/config/gridColumns.ts
import { GridColDef } from '@mui/x-data-grid';
import { Box, Button } from '@mui/material';
import { HPOSchema } from '@/zod_schemas';

interface GetHPOColumnsProps {
  deleteAction?: boolean;
  selectAction?: boolean;
  handleConfirmClick?: (newData: HPOSchema, type: string) => void;
  setDialogOpen?: React.Dispatch<React.SetStateAction<boolean>>;
  type?: string;
  formMode?: string;
  formPatientId?: string;
}

export const getHPOColumns = ({
  deleteAction = false, // Ensure deleteAction defaults to false
  selectAction = false, // Ensure selectAction defaults to false
  handleConfirmClick,
  setDialogOpen,
  type,
  formMode,
  formPatientId,
}: GetHPOColumnsProps): GridColDef<HPOSchema>[] => {
  const columns: GridColDef<HPOSchema>[] = [
    {
      field: 'hpo_id',
      headerName: 'Term Identifier',
      flex: 1,
    },
    {
      field: 'name',
      headerName: 'Term Name',
      flex: 1,
    },
    {
      field: 'synonyms',
      headerName: 'Matching String',
      flex: 1,
      renderCell: (params) => (
        <Box sx={{ whiteSpace: 'pre-line' }}>{params.value.join('\n')}</Box>
      ),
    },
  ];

  if (deleteAction) {
    columns.push({
      field: 'actions',
      headerName: 'Actions',
      sortable: false,
      flex: 0.8,
      renderCell: (params) => (
        <Box>
          <Button
            variant="contained"
            color="secondary"
            size="small"
            onClick={
              () => params
              // alert('Delete');
            }
            style={{ marginLeft: 8 }}
          >
            Delete
          </Button>
        </Box>
      ),
    });
  }

  if (selectAction) {
    columns.push({
      field: 'actions',
      headerName: 'Actions',
      sortable: false,
      flex: 0.8,
      renderCell: (params) => (
        <Box>
          <Button
            variant="contained"
            color="primary"
            size="small"
            onClick={() => {
              const rowData = params.row;

              if (
                formPatientId &&
                formMode === 'update' &&
                handleConfirmClick &&
                type
              ) {
                handleConfirmClick(rowData, type);
                console.log('rowData', rowData);
                console.log('bind');
                console.log('formPatientId', formPatientId);
              } else {
                if (handleConfirmClick && type) {
                  handleConfirmClick(rowData, type);
                }

                if (setDialogOpen) {
                  setDialogOpen(false);
                }
              }
            }}
            style={{ marginLeft: 8 }}
          >
            Select
          </Button>
        </Box>
      ),
    });
  }

  return columns;
};
