import { createBrowserRouter } from 'react-router-dom';

import MainDashboard from '@pages/common/MainDashboard';
import Register from '@pages/common/Register';
import Login from '@pages/common/Login/Login';
import SkeletonWithAuth from '@components/SkeletonWithAuth';
import FileBrowser from '@pages/common/FileBrowser';
import { Project, Projects } from '@pages/common/Project';
import { Patients, Patient } from '@pages/common/Patient';
import AccountPage from '@pages/common/Account/AccountPage';
import BatchConfig from '@pages/common/Project/BatchConfig';
import ViewPipeline from '@pages/common/Administrator/Pipeline/components/ViewPipeline';
import PipelinesPage from '@pages/common/Administrator/Pipeline/PipelinesPage';
import ProjectEdit from '@pages/common/Project/ProjectEdit';
import ResetPasswordPage from './pages/common/Login/ResetPasswordPage';

const router = createBrowserRouter([
  {
    path: '/login',
    element: <Login />,
  },
  {
    path: '/password_reset/reset/:uniqId',
    element: <ResetPasswordPage />,
  },
  {
    path: '/register',
    element: <Register />,
  },
  {
    path: '/',
    element: <SkeletonWithAuth />,
    children: [
      {
        index: true,
        element: <MainDashboard />,
      },
      {
        path: 'patients',
        children: [
          {
            index: true,
            element: <Patients />,
          },
          {
            path: 'create',
            element: <Patient />,
          },
          {
            path: ':patientId',
            element: <Patient />,
          },
        ],
      },
      {
        path: 'files',
        element: <FileBrowser />,
      },
      {
        path: 'projects',
        children: [
          {
            index: true,
            element: <Projects />,
          },
          {
            path: 'create',
            element: <Project />,
          },
          // {
          //   // Original
          //   path: ':projectId',
          //   element: <Project />,
          // },
          {
            // Original
            path: ':projectId',
            element: <ProjectEdit />,
          },
        ],
      },
      {
        path: 'batch',
        children: [
          {
            index: true,
            element: <BatchConfig />,
          },
        ],
      },
      {
        path: 'account',
        children: [
          {
            index: true,
            element: <AccountPage />,
          },
        ],
      },
      {
        path: 'administrator',
        children: [
          {
            path: 'pipeline',
            element: <PipelinesPage />,
          },
          {
            path: 'pipeline/create',
            element: <ViewPipeline />,
          },
          {
            path: 'pipeline/:pipelineId',
            element: <ViewPipeline />,
          },
        ],
      },
    ],
  },
]);

export default router;
