import { Divider, List, ListItemButton, Paper } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import { useListProjects } from '@api/project';
import ProjectListItem from '@pages/common/Project/Components/ProjectListItem';

const ProjectsList = () => {
  const { data } = useListProjects();
  const navigate = useNavigate();

  return (
    <Paper square>
      <List disablePadding>
        {data?.map(
          ({ id, name, description, created_by, experiment_count }) => (
            <>
              <ListItemButton
                key={id}
                disableGutters
                sx={{ paddingX: 1 }}
                onClick={() => navigate(`/projects/${id}`)}
              >
                <ProjectListItem
                  experimentCount={experiment_count}
                  createdBy={created_by}
                  description={description}
                  name={name}
                />
              </ListItemButton>
              <Divider />
            </>
          ),
        )}
      </List>
    </Paper>
  );
};

export default ProjectsList;
